import { every } from "lodash";
import PropTypes from "prop-types";
import React from "react";

import config from "../config";

export default class AlertManagementSwitchersAbstract extends React.Component {
  static propTypes = {
    data: PropTypes.object,
    onElementChange: PropTypes.func,
    disabled: PropTypes.bool,
    stockId: PropTypes.number,
    alertCategories: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        key: PropTypes.string
      })
    ),
    alertTypes: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        channels: PropTypes.arrayOf(PropTypes.string)
      })
    ),
    additionalSettingsCategories: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        key: PropTypes.string
      })
    )
  };

  static defaultProps = {
    alertCategories:
      config.vendor !== "expalerts"
        ? [
            {
              name: "Company News",
              key: "media_monitor"
            },
            {
              name: "Price Alerts",
              key: "price_alerts"
            },
            {
              name: "Recommandations/splits",
              key: "stock_messages"
            },
            {
              name: "SD Zones alerts",
              key: "demand_zone"
            }
          ]
        : [
            { name: "Interesting Situation", key: "interesting_situation" },
            { name: "Price Alerts", key: "price_alerts" },
            { name: "SD Zones alerts", key: "demand_zone" },
            {
              name: "Donchian Channel Strategy (period H1)",
              key: "donchain_h1"
            },
            {
              name: "Donchian Channel Strategy (period H4)",
              key: "donchain_h4"
            },
            {
              name: "Ichimoku Kinko Hyo Strategy (period H1)",
              key: "ichimoku_h1"
            },
            {
              name: "Ichimoku Kinko Hyo Strategy (period H4)",
              key: "ichimoku_h4"
            }
          ],

    alertTypes: [
      {
        name: "Turn on / Turn off",
        channels: [null]
      },
      {
        name: "Email",
        channels: ["email"]
      },
      {
        name: "Mobile apps",
        channels: ["android", "ios"]
      }
    ],
    generalSettingsCategories: [
      {
        name: "Webinars",
        key: "webinar"
      },
      {
        name: "Education events",
        key: "education_event"
      },
      {
        name: "Special offers",
        key: "special_offer"
      },
      {
        name: "Third party offerings",
        key: "third_party_offering"
      }
    ],
    additionalSettingsCategories: []
  };

  state = {
    processedElements: []
  };

  static getDerivedStateFromProps(props, state) {
    const { data, alertTypes, stockId } = props;

    const alertCategories = AlertManagementSwitchersAbstract.getAlertCategories(
      props
    );

    if (!data) {
      return state;
    }

    const shouldUseSpecificData = typeof stockId !== "undefined";

    const dataToUse = shouldUseSpecificData ? data.specific : data.global;

    let processedElements = [
      [
        {
          type: "description",
          value: " "
        },
        ...alertTypes.map(alertType => ({
          type: "description",
          value: alertType.name
        }))
      ]
    ];

    alertCategories.forEach(category => {
      let processedElement = [
        {
          type: "description",
          value: category.name,
          category: category.key
        }
      ];

      alertTypes.forEach(alertType => {
        const dataElements = Array.isArray(dataToUse)
          ? dataToUse.filter(element => {
              return (
                element.type === category.key &&
                alertType.channels.indexOf(element.channel) !== -1
              );
            })
          : [];

        let disabled = false;
        let value = category.notificationsEnabledByDefault;

        if (dataElements.length) {
          value = every(dataElements, element => element.status);
        }

        if (
          alertType.channels[0] !== null &&
          processedElement[1].value === false
        ) {
          disabled = true;
        }

        processedElement.push({
          type: "switcher",
          value,
          disabled,
          category: category.key,
          label: alertType.name,
          channels: alertType.channels
        });
      });

      processedElements.push(processedElement);
    });

    if (shouldUseSpecificData && data.global) {
      data.global.forEach(element => {
        if (element.status === false) {
          let row = processedElements.findIndex(
            item => item[1].category === element.type
          );

          if (row !== -1) {
            let col = processedElements[row].findIndex(
              item =>
                item.channels && item.channels.indexOf(element.channel) !== -1
            );

            processedElements[row][col].globallyDisabled = true;

            if (element.channel === null) {
              let cols = processedElements[row].map((element, index) => index);
              cols.shift();

              cols.forEach(col => {
                processedElements[row][col].disabled = true;
              });
            }
          }
        }
      });
    }

    return {
      ...state,
      processedElements
    };
  }

  static getAlertCategories(props) {
    const {
      alertCategories,
      isGeneralSettings,
      generalSettingsCategories,
      additionalSettingsCategories
    } = props;

    let categories = alertCategories;

    if (isGeneralSettings) {
      categories = [...categories, ...generalSettingsCategories];
    }

    return AlertManagementSwitchersAbstract.attachNotificationEnabledByDefaultToCategories(
      [...additionalSettingsCategories, ...categories]
    );
  }

  static attachNotificationEnabledByDefaultToCategories(categories) {
    return categories.map(category => {
      if (typeof category.notificationsEnabledByDefault === "undefined") {
        category.notificationsEnabledByDefault = true;
      }

      return category;
    });
  }
}
