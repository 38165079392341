import "../styles/default.scss";

import React from "react";
import { withRouter } from "react-router";

import { UrlProvider } from "../../../../../api/UrlProvider";
import withHomeLandingPage from "../../../../../hocs/withHomeLandingPage";
import withUserData from "../../../../../hocs/withUserData";
import SquaberLogoLoader from "../../../common/SquaberLogoLoader/SquaberLogoLoader";
import prepareEmbeddedLandingPagUrl from '../../../../../lib/prepareEmbeddedLandingPagUrl';

@withUserData
@withHomeLandingPage
@withRouter
class HomePageRedirect extends React.Component {
  state = {
    iframeLoaded: false
  };

  componentDidMount(): void {
    const { userData, getHomeLandingPage, history, locale } = this.props;

    if (userData) {
      history.replace(
        UrlProvider.getUrl("fe.desktopPage", {
          locale
        }) + window.location.search
      );
    } else {
      getHomeLandingPage();
    }
  }

  handleIframeLoad = () => {
    this.setState({ iframeLoaded: true });
  };

  render() {
    const { userData, homeLandingPageState } = this.props;
    const { iframeLoaded } = this.state;

    if (!userData && homeLandingPageState.data) {
      return (
        <div className="homepage-iframe-wrapper">
          {!iframeLoaded && <SquaberLogoLoader />}
          <iframe
            scrolling="yes"
            seamless="seamless"
            allowFullScreen
            width="100%"
            height="100%"
            src={prepareEmbeddedLandingPagUrl(homeLandingPageState.data.landingPageUrl)}
            onLoad={this.handleIframeLoad}
          />
        </div>
      );
    }

    return <SquaberLogoLoader />;
  }
}

export default HomePageRedirect;
