import React from "react";
import connect from "../i18n/connect";
import { finalizePaymentRoutine } from "../redux/modules/payment";
import { getPaymentFormLoading } from "../redux/modules/payment/selector";

export default function withPaymentForm(WrappedComponent) {
  const mapStateToProps = state => ({
    paymentFormLoading: getPaymentFormLoading(state)
  });

  const mapDispatchToProps = {
    finalizePayment: finalizePaymentRoutine
  };

  @connect(mapStateToProps, mapDispatchToProps)
  class WithPaymentForm extends React.Component {
    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return WithPaymentForm;
}
