import React from "react";
import connect from "../i18n/connect";
import {
  getPaymentDetails,
  getPaymentDetailsLoading
} from "../redux/modules/payment/selector";
import { paymentDetailsRoutine } from "../redux/modules/payment";

export default function withPaymentDetails(WrappedComponent) {
  const mapStateToProps = state => ({
    paymentDetails: getPaymentDetails(state),
    loading: getPaymentDetailsLoading(state)
  });

  const mapDispatchToProps = {
    fetchPaymentDetails: paymentDetailsRoutine
  };

  @connect(mapStateToProps, mapDispatchToProps)
  class WithPaymentDetails extends React.Component {
    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return WithPaymentDetails;
}
