import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import createMuiTheme from "@material-ui/core/styles/createMuiStrictModeTheme";
import amplitude from "amplitude-js";
import autoBind from "auto-bind";
import classNames from "classnames";
import Cookies from "js-cookie";
import qs from "qs";
import React from "react";
import { Helmet } from "react-helmet";
import { Router } from "react-router";
import { renderRoutes } from "react-router-config";

import config from "../config";
import withBottomNavigation from "../hocs/withBottomNavigation";
import withConsentSettingsWeb from "../hocs/withConsentSettingsWeb";
import withOverlay from "../hocs/withOverlay";
import withPaymentDetails from "../hocs/withPaymentDetails";
import withUserData from "../hocs/withUserData";
import withWebNetworkStatus from "../hocs/withWebNetworkStatus";
import { connect } from "../i18n/connect";
import AnalyticsEventTrackerWeb from "../lib/AnalyticsEventTracker/AnalyticsEventTrackerWeb";
import { getIsMobile } from "../lib/platformHelper";
import { showModal } from "../lib/react-redux-modal-provider";
import StackableModalProvider from "../lib/react-redux-modal-provider/StackableModalProviderWeb";
import SocketClient from "../lib/SocketIO/SocketClient";
import { actionHandlerRoutine } from "../redux/modules/actionHandler";
import { fetchAnalysisCategories } from "../redux/modules/analysis/analysisCategories";
import { actions as authActions } from "../redux/modules/auth/login";
import { getUserID } from "../redux/modules/auth/login/selector";
import { followedStocksRoutine } from "../redux/modules/followed";
import { fetchGlobalConfig } from "../redux/modules/globalConfig";
import { fetchManualStrategies } from "../redux/modules/manualStrategies";
import { fetchMarketingBarsRoutine } from "../redux/modules/marketingBars";
import { fetchMarketingMessagesRoutine } from "../redux/modules/marketingMessages";
import { shouldShowMarketingMessageBar } from "../redux/modules/marketingMessages/selector";
import { actions as notificationsActions } from "../redux/modules/notifications";
import { fetchRecommendedStocksRoutine } from "../redux/modules/recommendedStocks";
import { getAppStatus } from "../redux/modules/status/selector";
import { initTagsServiceRoutine } from "../redux/modules/tagsService";
import { actions as translationsActions } from "../redux/modules/translations";
import { fetchUserMessagesRoutine } from "../redux/modules/userMessages";
import muiTheme from "../theme/mui/mui_theme";
import Template from "./components/BaseTemplate";
import ActionInProgress from "./components/common/ActionInProgress";
import AmplitudePageViewHandler from "./components/common/AmplitudePageViewHandler/modules/default";
import AppErrorStateView from "./components/common/AppErrorStateView/modules/default";
import BrowserNotification from "./components/common/BrowserNotification";
import CanonicalTagProvider from "./components/common/CanonicalTagProvider";
import EmailConfirmationSnackbar from "./components/common/EmailConfirmationSnackbar";
import FloatingInformation from "./components/common/FloatingInformation";
import Intercom from "./components/common/Intercom";
import LanguageHelper from "./components/common/LanguageHelper";
import MarketingBars from "./components/common/MarketingBars";
import NetworkStatusProvider from "./components/common/NetworkStatusProvider/modules/default";
import UserActionsProvider from "./components/common/UserActionsProvider";
import UserMessagesHandler from "./components/common/UserMessagesHandler";
import ErrorPage from "./components/pages/ErrorPage";
import Page404 from "./components/pages/Page404";
import routes from "./routes";

const mapStateToProps = state => ({
  userID: getUserID(state),
  appStatus: getAppStatus(state),
  shouldShowMarketingMessageBar: shouldShowMarketingMessageBar(state)
});

const mapDispatchToProps = {
  addNotification: notificationsActions.addNotification,
  markAsRead: notificationsActions.markAsRead,
  fetchTranslations: translationsActions.fetchTranslations,
  fetchNotifications: notificationsActions.fetchNotifications,
  fetchMarketTranslations: translationsActions.fetchMarketTranslations,
  cookieLogin: authActions.cookieLogin,
  fetchFollowedStocks: followedStocksRoutine,
  fetchMarketingBars: fetchMarketingBarsRoutine,
  actionHandler: actionHandlerRoutine,
  fetchRecommendedStocks: fetchRecommendedStocksRoutine,
  fetchUserMessages: fetchUserMessagesRoutine,
  fetchMarketingMessages: fetchMarketingMessagesRoutine,
  initTagsService: initTagsServiceRoutine,
  fetchAnalysisCategories,
  fetchManualStrategies,
  fetchGlobalConfig
};

@connect(mapStateToProps, mapDispatchToProps)
@withBottomNavigation("navState")
@withUserData
@withPaymentDetails
@withOverlay
@withWebNetworkStatus
@withConsentSettingsWeb
class RootComponent extends React.Component {
  constructor(props) {
    super(props);

    autoBind.react(this);

    this.state = {
      currentNotification: {
        title: "",
        body: "",
        callback: () => {}
      }
    };

    this.Amplitude = amplitude
      .getInstance()
      .init(config.amplitude.apiKey, null, {
        includeReferrer: true,
        includeUtm: true,
        includeGclid: true
      });
  }

  getWebSocketRoomName(param) {
    return "user_id_" + param;
  }

  async componentDidMount() {
    this.handleActionFromUrl();
    this.handlePremiumBenefitsModal();

    const {
      fetchTranslations,
      fetchNotifications,
      fetchRecommendedStocks,
      fetchFollowedStocks,
      userID,
      cookieLogin,
      refreshUserDataPromiseCreator,
      fetchMarketingBars,
      fetchGlobalConfig,
      fetchUserMessages,
      fetchMarketingMessages,
      userData,
      refreshUserData,
      fetchPaymentDetails,
      fetchAnalysisCategories,
      fetchManualStrategies,
      initTagsService,
      fetchMarketTranslations
    } = this.props;

    fetchGlobalConfig();

    const urlParams = qs.parse(window.location.search, {
      ignoreQueryPrefix: true
    });

    const shouldRedirectToNativeAfterPayment = localStorage.getItem(
      config.shouldRedirectToNativeAfterPaymentStorageKey
    );

    if (
      shouldRedirectToNativeAfterPayment &&
      (urlParams.after_payment || urlParams.status === "PERFORMED") &&
      config.nativeAppScheme
    ) {
      localStorage.removeItem(
        config.shouldRedirectToNativeAfterPaymentStorageKey
      );
      window.location =
        config.nativeAppScheme + "/userSettings?defaultCategory=payments";
    }

    if (!shouldRedirectToNativeAfterPayment && urlParams.after_payment) {
      setTimeout(() => {
        refreshUserData();
        fetchPaymentDetails();
      }, 1000);
    }

    const authTokenFromUrl = urlParams.auth_token_for_login;
    const willRedirectToNativeAfterPayment =
      urlParams.will_redirect_to_native_after_payment;

    if (willRedirectToNativeAfterPayment) {
      localStorage.setItem(
        config.shouldRedirectToNativeAfterPaymentStorageKey,
        "true"
      );
    }

    if (authTokenFromUrl) {
      cookieLogin(authTokenFromUrl, !!userID);
    } else if (userID) {
      try {
        await refreshUserDataPromiseCreator();
        fetchUserMessages();
      } catch (error) {}
    } else {
      const authTokenFromCookie = Cookies.get(config.authTokenCookieName);

      if (authTokenFromCookie) {
        cookieLogin(authTokenFromCookie);
      }
    }

    initTagsService();
    fetchTranslations();
    fetchMarketTranslations();
    fetchNotifications();
    fetchMarketingBars();
    fetchRecommendedStocks();
    fetchMarketingMessages();
    fetchFollowedStocks();
    fetchPaymentDetails();
    fetchAnalysisCategories();
    fetchManualStrategies();

    if (userID) {
      amplitude.getInstance().setUserId(userID);

      SocketClient.init(this.props.userID);

      SocketClient.connect(
        config.socketIoKeys.notifications,
        this.handleNotification,
        this.getWebSocketRoomName(userID)
      );
    } else {
      amplitude.getInstance().setUserProperties({ user_status: "visitor" });
    }

    AnalyticsEventTrackerWeb.attemptTrackRegisterConversionUsingGTM();

    let link =
      document.querySelector("link[rel*='icon']") ||
      document.createElement("link");
    link.type = "image/x-icon";
    link.rel = "shortcut icon";
    link.href = `/favicon_${config.vendor}.png`;
    document.getElementsByTagName("head")[0].appendChild(link);

    this.handleIntroductionModal();

    if (userData && !userData.email_validated) {
      this.refreshUserDataInterval = setInterval(() => {
        refreshUserData();
      }, 10000);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { userData, fetchPaymentDetails } = this.props;

    if (prevProps.userID !== this.props.userID) {
      SocketClient.init(this.props.userID);

      SocketClient.connect(
        config.socketIoKeys.notifications,
        this.handleNotification,
        this.getWebSocketRoomName(this.props.userID)
      );

      fetchPaymentDetails();
    }

    if (!userData || userData.email_validated) {
      clearInterval(this.refreshUserDataInterval);
      this.refreshUserDataInterval = null;
    }
  }

  componentWillUnmount() {
    SocketClient.disconnect(this.getWebSocketRoomName(this.props.userID));
  }

  handleNotification(data) {
    this.props.addNotification(data);
    this.prepareNotification(data);
  }

  prepareNotification(data) {
    const { markAsRead } = this.props;

    this.setState({
      currentNotification: {
        title: config.title,
        body: data.message,
        notificationMetaData: data,
        callback: () => {
          markAsRead(data.id, false);
        }
      }
    });
  }

  handleActionFromUrl() {
    const { actionHandler } = this.props;
    const { search } = window.location;

    const searchParams = qs.parse(search, { ignoreQueryPrefix: true });

    if (searchParams.action) {
      actionHandler(searchParams);
    }
  }

  handleIntroductionModal() {
    if (localStorage.getItem(config.introductionModalStorageKey)) {
      localStorage.removeItem(config.introductionModalStorageKey);

      if (getIsMobile()) {
        showModal("IntroductionModal");
      }
    }
  }

  handlePremiumBenefitsModal() {
    const { search } = window.location;

    const searchParams = qs.parse(search, { ignoreQueryPrefix: true });

    if (searchParams.premium_benefits) {
      showModal("PremiumBenefitsModal");
    }
  }

  render() {
    const {
      userData,
      shouldShowMarketingMessageBar,
      navState,
      history,
      appStatus: { error, errorStatus, userDataUpdateReturnedUnexpectedError },
      disabledOverlay,
      networkStatus
    } = this.props;

    const { currentNotification } = this.state;

    let appClasses = classNames({
      "app-content": true,
      "opened-bottom-nav": !!navState,
      [`opened-${navState}`]: !!navState,
      "payment-alert": shouldShowMarketingMessageBar
    });

    if (userDataUpdateReturnedUnexpectedError) {
      return <AppErrorStateView />;
    }

    const hasEmailSnackbar =
      userData && !userData.email_validated && !disabledOverlay;

    return (
      <React.Fragment>
        <Helmet>
          <meta name="robots" content="index, follow" />
          <meta
            name="googlebot"
            content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
          />
          <meta
            name="bingbot"
            content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
          />
        </Helmet>
        <MuiThemeProvider theme={createMuiTheme(muiTheme)}>
          <Router history={history}>
            <CanonicalTagProvider />
            <div className={appClasses}>
              {hasEmailSnackbar ? <EmailConfirmationSnackbar /> : null}
              <FloatingInformation />
              <BrowserNotification data={currentNotification} />
              <Template>
                <>
                  {networkStatus === "offline" ? null : error ? (
                    errorStatus === 404 ? (
                      <Page404 />
                    ) : (
                      <ErrorPage />
                    )
                  ) : (
                    renderRoutes(routes)
                  )}
                  <NetworkStatusProvider />
                </>
              </Template>
              <StackableModalProvider />
              <UserActionsProvider />
              <MarketingBars />
              <UserMessagesHandler />
              <LanguageHelper />
              {config.vendor !== "expalerts" ? (
                <Intercom userData={userData} />
              ) : null}
              <AmplitudePageViewHandler />
            </div>
          </Router>
        </MuiThemeProvider>
        <ActionInProgress />
      </React.Fragment>
    );
  }
}

export default RootComponent;
