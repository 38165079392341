import CryptoJS from "crypto-js";

export default class UserDataHash {
  static passPhrase = "67b69634";

  static getHashFromUserData(userData) {
    return CryptoJS.AES.encrypt(
      JSON.stringify(userData),
      this.passPhrase
    ).toString();
  }

  static getUserDataFromHash(hash) {
    // Decrypt
    const bytes = CryptoJS.AES.decrypt(hash, this.passPhrase);

    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }
}
