import React from "react";

import config from "../config";
import ConsentModeHelperWeb from "../lib/ConsentModeHelper/ConsentModeHelperWeb";
import { showModal } from "../lib/react-redux-modal-provider";
import CookiesConsentEvent from "../lib/SalesManago/events/CookiesConsentEvent";
import withSmEventTracker from "./withSmEventTracker";

export default function withConsentSettingsWeb(WrappedComponent) {
  @withSmEventTracker
  class WithConsentSettingsWeb extends React.Component {
    componentDidMount() {
      const { userData, trackSMEvent } = this.props;

      // This needs to happen before GTM scripts execution
      if (config.trackSMEvents && userData?.contact_id) {
        window._smclientid = userData.contact_id;
      }

      ConsentModeHelperWeb.applyDefaultConsent();
      const consentSettings = ConsentModeHelperWeb.attemptLoadConsentSettings();

      if (
        consentSettings &&
        ConsentModeHelperWeb.validateSettings(consentSettings)
      ) {
        ConsentModeHelperWeb.applyConsentSettings(consentSettings);
      } else if (ConsentModeHelperWeb.shouldShowModal()) {
        showModal("ConsentV2Modal", {
          disableBackdropClick: true,
          disableEscapeKeyDown: true,
          onApplySettings: settings => {
            if (ConsentModeHelperWeb.validateSettings(settings)) {
              ConsentModeHelperWeb.applyConsentSettings(settings);

              const smEvent = new CookiesConsentEvent(settings);
              trackSMEvent(smEvent);
            }
          }
        });
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return WithConsentSettingsWeb;
}
