import { createAction, handleActions } from "redux-actions";
import { createRoutine } from "redux-saga-routines";

export const paymentDetailsRoutine = createRoutine(
  "app/payment/PAYMENT_DETAILS"
);

export const updateCreditCardRoutine = createRoutine(
  "app/payment/UPDATE_CREDIT_CARD"
);

export const finalizePaymentRoutine = createRoutine(
  "app/payment/FINALIZE_PAYMENT"
);

export const releasePaymentRoutine = createRoutine(
  "app/payment/RELEASE_PAYMENT"
);

export const handlePaymentAfterRedirectRoutine = createRoutine(
  "app/payment/HANDLE_PAYMENT_AFTER_REDIRECT"
);

export const CLEAR_PAYMENT_DETAILS = "app/payment/CLEAR_PAYMENT_DETAILS";
export const clearPaymentDetails = createAction(CLEAR_PAYMENT_DETAILS);

export const initialState = {
  loading: false,
  error: null,
  data: {},
  paymentFormLoading: false
};

export default handleActions(
  {
    [paymentDetailsRoutine.TRIGGER]: state => state,
    [paymentDetailsRoutine.REQUEST]: state => ({
      ...state,
      loading: true
    }),
    [paymentDetailsRoutine.SUCCESS]: (state, action) => ({
      ...state,
      loading: false,
      data: action.payload
    }),
    [paymentDetailsRoutine.FAILURE]: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload
    }),
    [paymentDetailsRoutine.FULFILL]: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload
    }),
    [CLEAR_PAYMENT_DETAILS]: () => initialState,
    [finalizePaymentRoutine.TRIGGER]: state => ({
      ...state,
      paymentFormLoading: true
    }),
    [finalizePaymentRoutine.SUCCESS]: state => ({
      ...state,
      paymentFormLoading: false
    }),
    [finalizePaymentRoutine.FAILURE]: state => ({
      ...state,
      paymentFormLoading: false
    })
  },
  initialState
);
