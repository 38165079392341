import { fork, put, select, takeLatest } from "redux-saga/effects";

import ApiClient from "../../../api/ApiClient";
import { UrlProvider } from "../../../api/UrlProvider";
import config from "../../../config";
import AnalyticsEventTracker from "../../../lib/AnalyticsEventTracker";
import { actions as alertActions } from "../alerts";
import { actions as loginActions } from "../auth/login";
import { getLocale } from "../translations/selector";
import { actionHandlerRoutine } from "./index";

function* handleAction({ payload }) {
  const { action: url, ...data } = payload;
  const userId = data.user_id;

  try {
    const { data: responseData } = yield ApiClient.post({
      url: `${config.apiHostNameWithoutTrailingSlash}${url}`,
      data
    });

    if (responseData.message) {
      yield put(
        alertActions.addSuccessAlert({
          title: responseData.message
        })
      );
    }

    if (typeof data.register !== "undefined") {
      const locale = yield select(getLocale);
      AnalyticsEventTracker.trackRegistrationConfirmationConversion(userId);
      yield put(loginActions.refreshUserData());
      const redirectUrl = UrlProvider.getUrl("fe.settingsPageCategory", {
        locale,
        settingsCategory: "notifications"
      });

      window.location.replace(redirectUrl);
    }
  } catch (e) {
    if (e.request) {
      const { response } = e.request;
      const parsedResponse = JSON.parse(response);

      yield put(
        alertActions.addErrorAlert({
          title: parsedResponse.message
        })
      );
    }
  }
}

function* watchHandleAction() {
  yield takeLatest(actionHandlerRoutine.TRIGGER, handleAction);
}

export default [fork(watchHandleAction)];
