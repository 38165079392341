import accessLevelsMap from "../access/accessLevelsMap";

export default function getIntercomUserData(userData, accessLevel) {
  if (!userData) {
    return {};
  }

  return {
    email: userData.email,
    user_id: String(userData.id),
    name: userData.username,
    first_name: userData.first_name,
    email_validated: userData.email_validated,
    access_level:
      accessLevel === accessLevelsMap.HAS_PREMIUM_ACCESS
        ? "premium"
        : "freemium",
    personal_data_processing: userData.personal_data_processing,
    electronic_communication: userData.electronic_communication,
    marketing_emails: userData.marketing_emails,
    user_hash: userData.intercom_user_hash
  };
}
