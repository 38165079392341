import { takeLatest, fork, put } from "redux-saga/effects";
import { fetchManualStrategies } from "./index";
import apiClient from "../../../api/ApiClient";
function* onFetchManualStrategies() {
  let results = [];
  const urlPaths = ["app.strategies.manual", "app.strategies.transaction"];

  try {
    for (const urlPath of urlPaths) {
      const { data } = yield apiClient.get({ urlPath, skipAccessCheck: true });

      results.push(...data);
    }

    yield put(
      fetchManualStrategies.success(
        results.map(strategy => ({
          name: strategy.name,
          key: strategy.notifications_unique_id,
          notificationsEnabledByDefault:
            strategy?.notifications_enabled_by_default ?? true
        }))
      )
    );
  } catch (e) {
    yield put(fetchManualStrategies.failure(e));
  }
}

function* watchGetManualStrategies() {
  yield takeLatest(fetchManualStrategies.TRIGGER, onFetchManualStrategies);
}

export default [fork(watchGetManualStrategies)];
