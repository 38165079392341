import React from "react";
import autoBind from "auto-bind";
import withAlertManagement from "../../../../../hocs/withAlertManagement";
import StockAlertStatus from "../../StockAlertStatus";
import AlertManagementSwitchers from "../../AlertManagementSwitchers";
import envVariables from "../../../../../lib/envHelpers/getEnvVariables";
import { UrlProvider } from "../../../../../api/UrlProvider";

import "../styles/default.scss";
import withStockIsFollowed from "../../../../../hocs/withStockIsFollowed";
import { compose } from "redux";

class AlertManagement extends React.Component {
  constructor(props) {
    super(props);

    autoBind.react(this);
  }

  onElementChange(element) {
    const { stockId, updateAlertManagementData } = this.props;

    element.channels.forEach(channel => {
      updateAlertManagementData({
        stockId,
        channel,
        category: element.category,
        status: element.status
      });
    });
  }

  render() {
    const {
      stockId,
      translate,
      alertManagementData,
      loading,
      locale,
      market,
      ticker,
      manualStrategies,
      analysisCategories,
      stockIsFollowed
    } = this.props;

    return (
      <div className="alert-management">
        <div className="row">
          <div className="col-md-4">
            <div className="alert-status-wrapper">
              <StockAlertStatus
                stockId={stockId}
                sliderOnMobile
                market={market}
                ticker={ticker}
              />
            </div>
          </div>
          <div className="col-md-8">
            <p
              className="alert-management-description"
              dangerouslySetInnerHTML={{
                __html: translate(
                  "In this section you can change alerts settings for this particular asset. Remmeber, if you turn off the specific alert type in <a target='_blank' href='%{generalSettingsUrl}'>general settings</a>, then turning it on in this section, will not affect its operation.",
                  {
                    generalSettingsUrl: UrlProvider.getUrl(
                      "fe.settingsPageCategory",
                      {
                        locale,
                        settingsCategory: "notifications"
                      },
                      envVariables.NODE_ENV === "production" &&
                        envVariables.TARGET_ENV !== "staging"
                        ? "https://squaber.com/"
                        : "/"
                    )
                  }
                )
              }}
            />
          </div>
        </div>
        <AlertManagementSwitchers
          stockId={stockId}
          data={alertManagementData ? alertManagementData : null}
          disabled={loading || !stockIsFollowed}
          onElementChange={this.onElementChange}
          additionalSettingsCategories={[
            ...manualStrategies.data,
            ...analysisCategories.map(category => ({
              key: category.notifications_unique_id,
              name: category.name,
              notificationsEnabledByDefault:
                category?.notifications_enabled_by_default ?? true
            }))
          ]}
        />
      </div>
    );
  }
}

AlertManagement.propTypes = {};

export default compose(
  withAlertManagement,
  withStockIsFollowed(props => props.stockId)
)(AlertManagement);
