import React from "react";
import PropTypes from "prop-types";
import qs from "qs";
import { withRouter } from "react-router";
import config from "../../../../../config";
import withTranslations from "../../../../../hocs/withTranslations";
import PageHelper from "../../../../../lib/PageHelper";
import getAccessLevel from "../../../../../lib/access/getAccessLevel";
import UserDataHash from "../../../../../lib/UserDataHash";
import getIntercomUserData from "../../../../../lib/Intercom/getIntercomUserData";

@withRouter
@withTranslations
class Intercom extends React.Component {
  static propTypes = {
    userData: PropTypes.object
  };

  booted = false;

  getUserDataObject() {
    let { userData } = this.props;

    if (!userData) {
      const queryDataFromUrl = qs.parse(
        window.location.search.replace("?", "")
      );

      if (queryDataFromUrl.user) {
        const queryData = UserDataHash.getUserDataFromHash(
          queryDataFromUrl.user
        );

        if (
          typeof queryData.email !== "undefined" &&
          typeof queryData.id !== "undefined" &&
          typeof queryData.username !== "undefined"
        ) {
          userData = { ...queryData };
        }

        if (userData && typeof userData === "object") {
          Object.keys(userData).forEach(key => {
            if (userData[key] === "false") {
              userData[key] = false;
            }
            if (userData[key] === "true") {
              userData[key] = true;
            }
          });
        }
      }
    }

    const accessLevel = getAccessLevel(userData);

    return getIntercomUserData(userData, accessLevel);
  }

  getIsRegisterPage() {
    const { location, locale } = this.props;

    return PageHelper.getIsRegisterPage(location, locale);
  }

  componentDidMount(): void {
    const userDataObject = this.getUserDataObject();
    const isRegisterPage = this.getIsRegisterPage();

    if (!isRegisterPage) {
      this.boot(userDataObject);
    }
  }

  componentDidUpdate(
    prevProps: Readonly<P>,
    prevState: Readonly<S>,
    snapshot: SS
  ): void {
    const userDataObject = this.getUserDataObject();
    const isRegisterPage = this.getIsRegisterPage();

    if (!isRegisterPage) {
      if (this.booted) {
        this.update(userDataObject);
      } else {
        this.boot(userDataObject);
      }
    }
  }

  boot(userDataObject) {
    require("../../../../../lib/Intercom");

    window.Intercom("boot", {
      app_id: config.intercomAppID,
      ...userDataObject
    });

    this.booted = true;
  }

  update(userDataObject) {
    window.Intercom("update", userDataObject);
  }

  render() {
    return null;
  }
}

export default Intercom;
