import amplitude from "amplitude-js";
import uuidv4 from "uuid/v4";
import moment from "moment-mini";
import config from "../../config";

export default class AnalyticsEventTrackerWeb {
  static localStorageKey = "REGISTRATION_INFO";

  static trackPaymentConversion(params) {
    const amountNumber = Number(params.amount);
    const transactionId = uuidv4();

    if (config.trackEvents) {
      window.dataLayer = window.dataLayer || [];

      // Old Analytics
      window.dataLayer.push({
        ecommerce: {
          purchase: {
            actionField: {
              id: transactionId,
              affiliation: "",
              revenue: amountNumber.toString(),
              tax: (
                Math.round((amountNumber - amountNumber / 1.23) * 100) / 100
              ).toString(),
              shipping: 0,
              coupon: 0
            },
            products: [
              {
                name: "Squaber subscription",
                id: params.id.toString(),
                price: amountNumber.toString(),
                brand: "",
                category: "",
                variant: "",
                quantity: 1,
                coupon: ""
              }
            ]
          }
        }
      });

      // New analytics
      window.dataLayer.push({
        event: "purchase",
        transactions_id: transactionId,
        product_id: params.id.toString(),
        value: amountNumber.toString(),
        tax: (
          Math.round((amountNumber - amountNumber / 1.23) * 100) / 100
        ).toString(),
        currency: "PLN",
        items: "1"
      });
    }

    if (config.amplitude.trackRevenue) {
      let revenue = new amplitude.Revenue({
        transactions_id: transactionId,
        tax: (
          Math.round((amountNumber - amountNumber / 1.23) * 100) / 100
        ).toString(),
        currency: "PLN",
        items: "1"
      })
        .setProductId(params.id.toString())
        .setPrice(Number(amountNumber));

      amplitude.getInstance().logRevenueV2(revenue);
    }
  }

  static trackRegisterConversion(registerSource, userId, registrationMethod) {
    const conversionData = {
      event: "Registration",
      registerSource,
      userId
    };

    if (!registrationMethod) {
      registrationMethod = "form";
    }

    window.localStorage.setItem(
      this.localStorageKey,
      JSON.stringify(conversionData)
    );

    if (config.trackEvents) {
      if ("ga" in window) {
        const tracker = window.ga.getAll()[0];
        if (tracker) {
          tracker.send("event", "Registration", registerSource);
        }
      }
    }

    amplitude.getInstance().setUserId(String(userId));

    if (config.amplitude.trackRegistration) {
      amplitude.getInstance().logEvent("register", {
        registerSource,
        registration_method: registrationMethod
      });
    }
  }

  static attemptTrackRegisterConversionUsingGTM() {
    const conversionData = JSON.parse(
      window.localStorage.getItem(this.localStorageKey)
    );

    if (conversionData) {
      window.dataLayer = window.dataLayer || [];

      window.dataLayer.push({
        event: "Registration",
        registerSource: conversionData.registerSource,
        user_id: conversionData.userId
      });

      window.localStorage.removeItem(this.localStorageKey);
    }
  }

  static trackRegistrationConfirmationConversion(userId) {
    window.dataLayer.push({
      event: "Registration_confirmed",
      user_id: userId
    });

    if (config.trackEvents) {
      if ("ga" in window) {
        const tracker = window.ga.getAll()[0];
        if (tracker) {
          tracker.send("event", "Registration confirmed", {
            user_id: userId
          });
        }
      }
    }

    if (config.amplitude.trackRegistrationConfirmation) {
      amplitude.getInstance().logEvent("registration_confirmation", {
        confirmation_date: moment().format("DD-MM-YYYY"),
        user_id: userId
      });
    }
  }

  static trackUserLogInEvent() {
    amplitude.getInstance().logEvent("log_in");
  }

  static trackOfferModalEvent(id = null) {
    const type = id === null ? "premium_modal" : "marketing_message";

    amplitude.getInstance().logEvent("offer_modal", {
      marketing_message_id: id,
      type
    });
  }

  static trackRegistrationModalEvent(trigger) {
    amplitude.getInstance().logEvent("registration_modal", {
      trigger
    });
  }

  static trackPortfolioCreatedEvent() {
    amplitude.getInstance().logEvent("portfolio_created");
  }

  static trackPortfolioTransactionEvent() {
    amplitude.getInstance().logEvent("portfolio_transaction");
  }

  static trackStockAlertOnEvent(type, market, name) {
    amplitude.getInstance().logEvent("stock_alert_on", {
      type,
      market,
      name
    });
  }

  static trackTagCreatedEvent() {
    amplitude.getInstance().logEvent("tag_created");
  }

  static trackAppInstallationAttemptEvent() {
    amplitude.getInstance().logEvent("download_mobile_app_click");
  }

  static trackSearchListStockOnClickEvent({
    searchListName,
    market,
    ticker,
    searchListPosition
  }) {
    amplitude.getInstance().logEvent("search_list_stock_click", {
      search_list_name: searchListName,
      market,
      ticker,
      position: searchListPosition
    });
  }

  static trackSearchResultOnClickEvent({ market, ticker }) {
    amplitude.getInstance().logEvent("user_search_click", {
      market,
      ticker
    });
  }

  static trackSearchPageShownEvent() {
    amplitude.getInstance().logEvent("search_click");
  }
}
