import { connect } from "react-redux";

import { showModal, hideModal, removeModal } from "./action";
import BaseModalProviderWeb from "./BaseModalProviderWeb";

class StackableModalProviderWeb extends BaseModalProviderWeb {}

export default connect(
  ({ modalProvider }) => ({
    modalProvider
  }),
  {
    showModal,
    hideModal,
    removeModal
  }
)(StackableModalProviderWeb);
