import React, { Component } from "react";
import PropTypes from "prop-types";
import eventEmitter from "./eventEmitter";

export default class BaseModalProviderAbstract extends Component {
  static propTypes = {
    showModal: PropTypes.func.isRequired,
    hideModal: PropTypes.func.isRequired,
    removeModal: PropTypes.func.isRequired,
    modalProvider: PropTypes.shape({
      stack: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          component: PropTypes.oneOfType([
            PropTypes.element,
            PropTypes.func,
            PropTypes.string
          ]).isRequired,
          props: PropTypes.object,
          show: PropTypes.bool.isRequired
        })
      )
    }).isRequired
  };

  UNSAFE_componentWillMount() {
    eventEmitter.on("showModal", args => this.props.showModal(...args));
  }

  componentWillUnmount() {
    eventEmitter.removeListener("showModal");
  }

  hideModal(id) {
    this.props.hideModal(id);
    setTimeout(() => {
      this.props.removeModal(id);
    }, 100);
  }

  render() {
    const {
      modalProvider: { stack }
    } = this.props;
    return (
      <React.Fragment>
        {stack.map((modal, index) => {
          let isLast = stack.length - index === 1;
          const ModalComponent = this.modals[modal.component];
          return (
            <ModalComponent
              key={`@${this.constructor.name}_MODAL_${modal.id}`}
              {...modal.props}
              hideModal={() => this.hideModal(modal.id)}
              isLast={isLast}
              show={modal.show}
            />
          );
        })}
      </React.Fragment>
    );
  }
}
