import { isEmpty } from "lodash";
import qs from "qs";
import React from "react";
import { withRouter } from "react-router";

import withAddAlert from "../../../../../hocs/withAddAlert";
import withPaymentDetails from "../../../../../hocs/withPaymentDetails";
import withTransactionHistory from "../../../../../hocs/withTransactionHistory";
import withUserData from "../../../../../hocs/withUserData";
import { checkAccessTime } from "../../../../../lib/access/time";
import Loader from "../../../common/Loader";
import CurrentPlanBox from "../../CurrentPlanBox";
import PaymentMethodBox from "../../PaymentMethodBox";
import PremiumOfferBox from "../../PremiumOfferBox";
import TransactionHistoryBox from "../../TransactionHistoryBox";

@withPaymentDetails
@withTransactionHistory
@withUserData
@withRouter
@withAddAlert
class PaymentsSettings extends React.Component {
  componentDidMount(): void {
    const {
      location: { search },
      addErrorAlert,
      translate,
      fetchPaymentDetails
    } = this.props;

    const queryParams = qs.parse(search.replace("?", ""));

    if (queryParams.error && queryParams.error === "501") {
      addErrorAlert({
        title: translate("Authorization error.")
      });
    }

    fetchPaymentDetails();
  }

  render() {
    const {
      paymentDetails,
      userData,
      transactionHistoryData,
      loading
    } = this.props;

    if (loading) {
      return (
        <div className="payments-settings">
          <div className="content-section">
            <Loader />
          </div>
        </div>
      );
    }

    if (isEmpty(paymentDetails) || isNaN(paymentDetails.end_date_in_seconds)) {
      return null;
    }

    const accessTime = checkAccessTime(paymentDetails, userData);

    return (
      <div className="payments-settings">
        {accessTime.noPremiumAccess ||
        accessTime.expired ||
        (paymentDetails.end_date && !paymentDetails.user_offer_id) ? (
          <PremiumOfferBox />
        ) : null}
        <React.Fragment>
          {paymentDetails.user_offer_id || paymentDetails.end_date ? (
            <CurrentPlanBox paymentDetails={paymentDetails} />
          ) : null}
          {paymentDetails.resale_date ? <PaymentMethodBox /> : null}
          {transactionHistoryData.data && transactionHistoryData.data.length ? (
            <TransactionHistoryBox
              transactionHistoryData={transactionHistoryData}
            />
          ) : null}
        </React.Fragment>
      </div>
    );
  }
}

PaymentsSettings.propTypes = {};

export default PaymentsSettings;
