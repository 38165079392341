import React from "react";
import moment from "moment-mini";
import withTranslations from "../../../../../hocs/withTranslations";
import ContentSection from "../../../common/ContentSection";
import SquaberTable from "../../../common/SquaberTable";
import Price from "../../../../../common/Price";

const TransactionHistoryBox = ({ translate, transactionHistoryData }) => (
  <ContentSection>
    <h2 className="big-heading">{translate("Transactions history")}</h2>
    <SquaberTable
      heading={[translate("Date"), translate("Amount"), translate("Period")]}
      rows={transactionHistoryData.data.map(item => [
        moment(item.datetime_utc).format("DD.MM.YYYY[,] HH:mm"),
        new Price({ value: item.amount, currency: item.currency }).render(),
        `${item.periods * 30} ${translate("days")}`
      ])}
    />
  </ContentSection>
);

export default withTranslations(TransactionHistoryBox);
