import React from "react";
import moment from "moment-mini";
import { compose } from "redux";
import { withRouter } from "react-router";
import { globalConfigFields } from "../../../../../redux/modules/globalConfig";
import withGlobalConfigFields from "../../../../../hocs/withGlobalConfigFields";
import withUpdateCreditCard from "../../../../../hocs/withUpdateCreditCard";
import { getTimeFromSeconds } from "../../../../../lib/access/time";
import { UrlProvider } from "../../../../../api/UrlProvider";
import ContentSection from "../../../common/ContentSection";
import SquaberButton from "../../../common/SquaberButton";
import config from "../../../../../config";

import "../styles/default.scss";

const CurrentPlanBox = ({
  translate,
  locale,
  paymentDetails,
  releasePayment,
  globalConfig: { offerUrl },
  history: { push }
}) => {
  const {
    currency,
    offers,
    user_offer_id,
    start_date,
    end_date,
    end_date_in_seconds,
    resale_date
  } = paymentDetails;
  const currentOffer = offers.find(offer => offer.id === user_offer_id);

  let timePeriod;

  if (currentOffer) {
    timePeriod = currentOffer.periods === 12 ? "year" : "month";
    currentOffer.amountNumber = Number(currentOffer.amount);
  }

  const startDate = moment(start_date).format("DD.MM.YYYY");
  const endDate = moment(end_date).format("DD.MM.YYYY");

  let currentPlanDetails = [];

  if (currentOffer) {
    currentPlanDetails.push({
      description: translate(
        end_date ? "Your current plan" : "Your previous plan"
      ),
      content: (
        <span>
          {currentOffer.days} {translate("days")} - {currentOffer.amountNumber}{" "}
          {currency}/{translate(timePeriod)}
        </span>
      )
    });
  }

  if (end_date) {
    currentPlanDetails.push(
      {
        description: translate("Plan start date"),
        content: <span>{startDate}</span>
      },
      {
        description: translate("Plan end date"),
        content: <span>{endDate}</span>
      },
      {
        description: translate("Time remaining"),
        content: (
          <span>
            {Math.round(getTimeFromSeconds(end_date_in_seconds).days)}{" "}
            {translate("days")}
          </span>
        )
      }
    );
  }

  return (
    <div className="current-plan">
      <ContentSection>
        <h2 className="big-heading">{translate("Your plan")}</h2>
        <p>
          {end_date
            ? translate(
                !currentOffer
                  ? "Below you will find information about currently used plan"
                  : "Below you will find information about currently used plan. You can also change your plan or payment method"
              )
            : translate(
                "Below you will find information about previously used plan"
              )}
        </p>
        <div className="current-plan-details">
          {currentPlanDetails.map((detail, key) => (
            <div className="current-plan-detail" key={key}>
              <span className="description">{detail.description}:</span>
              <span className="content">{detail.content}</span>
            </div>
          ))}
        </div>
        {currentOffer ? (
          resale_date ? (
            <React.Fragment>
              <h4 className="current-offer-heading">
                {translate("current-offer-heading")}
              </h4>
              <p className="prolongation-details">
                {translate(
                  "Your plan will be automatically prolonged on %{resale_date} and you will be billed %{value} %{currency}.",
                  {
                    resale_date: moment(resale_date).format("DD.MM.YYYY"),
                    value: currentOffer.amountNumber,
                    currency
                  }
                )}
              </p>
              <div className="buttons-wrapper">
                <SquaberButton
                  onClick={() => {
                    window.open(
                      offerUrl || config.defaultPremiumOfferUrl,
                      "_blank"
                    );
                  }}
                >
                  {translate("Change plan")}
                </SquaberButton>
                <SquaberButton
                  color="secondary"
                  onClick={() => {
                    releasePayment();
                  }}
                >
                  {translate("Cancel premium")}
                </SquaberButton>
              </div>
            </React.Fragment>
          ) : (
            <div className="last-offer-renewal">
              <h4 className="current-offer-heading">
                {translate("current-offer-heading")}
              </h4>
              <p>
                <span>{translate("Use your last offer again")}:</span>
                <span className="last-offer-value">
                  {currentOffer.days} {translate("days")} -{" "}
                  {currentOffer.amountNumber} {currency}/{translate(timePeriod)}
                </span>
              </p>
              <SquaberButton
                onClick={() => {
                  push(
                    UrlProvider.getUrl("fe.paymentForm", {
                      locale,
                      offerId: user_offer_id
                    })
                  );
                }}
              >
                {translate("Use")}
              </SquaberButton>
              <SquaberButton
                color="secondary"
                onClick={() => {
                  window.open(
                    offerUrl || config.defaultPremiumOfferUrl,
                    "_blank"
                  );
                }}
              >
                {translate("personal_offers_see_current_squaber_offer")}
              </SquaberButton>
            </div>
          )
        ) : null}
      </ContentSection>
    </div>
  );
};

CurrentPlanBox.propTypes = {};

export default compose(
  withUpdateCreditCard,
  withRouter,
  withGlobalConfigFields([globalConfigFields.OFFER_URL])
)(CurrentPlanBox);
