const apiHostName = "api.aws.squaber.com";

module.exports = {
  apiHostName: `https://${apiHostName}/`,
  apiHostNameWithoutTrailingSlash: `https://${apiHostName}`,
  apiUrl: `https://${apiHostName}/:locale/api/`,
  baseHostName: "squaber.com",
  firebase: {
    apiKey:
      "AAAAM9wTFK0:APA91bETjQMG58ETrGdkb3ODFGIPgdlTzr9FhVg_o3O2U1mrJpCV1uzZYSzACD2_Q_vWU2tO-YjrgL7f4gJEot0uXwe_wk3e0wdnU2tQk9j2p7ooBM6b22JP843zvyfB7Rge2FiKPiXS",
    authDomain: "investio-backup.firebaseapp.com",
    databaseURL: "https://squaber-production.firebaseio.com/",
    storageBucket: "investio-backup.appspot.com"
  },
  googleReCaptcha: {
    url: "https://squaber.com",
    sitekey: "6LcDZ54nAAAAAAVVIDyk0pqUreu7ToE_9j7mGhaK"
  },
  websocketUrl: "https://socketio.aws.squaber.com:444",
  tradingViewOptions: {
    hasIntraday: true,
    marketsIntervals: [
      {
        markets: ["gpw", "nc", "crypto", "glo"],
        intervals: ["1", "5", "15", "30", "60", "240", "D", "W", "M"]
      },
      {
        markets: ["metals", "energies", "commodities", "indices", "forex"],
        intervals: ["30", "60", "240", "D", "W", "M"]
      }
    ],
    defaultMarketsIntervals: ["D", "W", "M"]
  },
  quoteConvertersInUse: false,
  defaultPremiumPopupUrl: "https://l.squaber.com/pop-up-premium/",
  webAppUrl: "https://squaber.com/",
  native_tv_enabled: true,
  intercomAppID: "v1fl7dqi",
  trackEvents: true,
  gtm: {
    trackingId: "GTM-K3LKB5T"
  },
  buildNumber: 1,
  currentTag: "6.0.1",
  facebookCredentials: {
    appId: 413550755439326,
    clientToken: "4ccc7d84bc5cc0cd92a5fa7e22507ae9"
  },
  nativeAppScheme: "investio.squaber://",
  amplitude: {
    apiKey: "24e0c8a7bde2417fb141dc37044dd110",
    trackRegistration: false,
    trackRegistrationConfirmation: false,
    trackRevenue: false
  },
  numberOfDaysUntilAffiliateCookieExpiry: 60,
  defaultTagId: 67447,
  purchasesDebugLog: false,
  revenueCatApiKey: {
    ios: "appl_xtSyqfijEQvYIWDBFmZPCqDbzVY",
    android: "goog_yFGEpcNeGlIHyYZTbTvWwkHbUuI"
  },
  staticFilesHostName: "squaber-statics.s3.amazonaws.com",
  availableMarkets: [
    "GPW",
    "NYSE",
    "NASDAQ",
    "FUNDPL",
    "NC",
    "GLO",
    "METALS",
    "ENERGIES",
    "COMMODITIES",
    "INDICES",
    "FOREX",
    "CRYPTO"
  ],
  expoProjectNameForProxy: "@squaber/squaber",
  expoSchemeFallback: "investio.squaber",
  trackSMEvents: true
};
