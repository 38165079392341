import React, { useCallback, useEffect, useState } from "react";

import withStockPageTVChart from "../../../../../hocs/withStockPageTVChart";
import Loader from "../../../common/Loader";
import TVChart from "../../../common/TVChart";

function StockPageTVChart({
  stock,
  stockLoading,
  stockId,
  marketId,
  fetchStock,
  onRefreshEnded
}) {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchStock({ stockId, marketId });
  }, [stockId]);

  const canShowTvChart = stock?.stock && stock?.latest_quote_datetime_utc;

  useEffect(() => {
    if (!canShowTvChart) {
      onRefreshEnded?.();
    }
  }, [canShowTvChart]);

  const handleLoadingStarted = useCallback(() => {
    setLoading(true);
  }, []);

  const handleLoadingEnded = useCallback(() => {
    setLoading(false);
    onRefreshEnded?.();
  }, []);

  return (
    <div className={"StockPageTVChart__wrapper"}>
      {canShowTvChart ? (
        <TVChart
          onRefreshStarted={handleLoadingStarted}
          onRefreshEnded={handleLoadingEnded}
          stock={stock.stock}
          latestQuoteDatetimeUtc={stock.latest_quote_datetime_utc}
        />
      ) : null}
      {stockLoading || loading ? (
        <Loader className={"StockPageTVChart__loader"} />
      ) : null}
    </div>
  );
}

export default withStockPageTVChart(StockPageTVChart);
