import React, { useEffect } from "react";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import withTranslations from "../../../../../hocs/withTranslations";
import withUserData from "../../../../../hocs/withUserData";
import { UrlProvider } from "../../../../../api/UrlProvider";

import config from "../../../../../config";

const parseLang = (lang: string) => {
  const defaultLang = config.defaultLocale;
  const acceptedLanguages = config.supportedLocales;

  if (typeof lang !== "string") return defaultLang;

  const lowerCaseLang = lang.toLowerCase();
  if (acceptedLanguages.includes(lowerCaseLang)) return lowerCaseLang;

  return defaultLang;
};

function LanguageHelper({ setLocale, userData, history }) {
  const lang = userData?.lang;

  useEffect(() => {
    handleLanguageChange();
  }, [lang]);

  const handleLanguageChange = () => {
    if (typeof setLocale !== "function" || typeof lang !== "string") return;

    const pathname = window?.location?.pathname;

    if (typeof pathname === "undefined") return;

    const parsedLang = parseLang(lang);
    const containCurrentLang = new RegExp(`/${parsedLang}/`, "g");

    if (containCurrentLang.test(pathname)) return;

    const newUrl = UrlProvider.replaceLocaleFromUrl(pathname, parsedLang);

    setLocale(parsedLang);
    history.push(newUrl);
  };

  return null;
}

export default compose(
  withTranslations,
  withRouter,
  withUserData
)(LanguageHelper);
