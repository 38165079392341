import getDiscountAmount from "./getDiscountAmount";
import hasDiscount from "./hasDiscount";

function getDiscountOffer({ amount = 0, discounted_amount = 0 }) {
  if (hasDiscount({ amount, discounted_amount })) {
    const usedPoints = getDiscountAmount({
      amount,
      discounted_amount
    });

    return {
      amountAfterDiscount: amount - usedPoints,
      usedAffiliatePoints: usedPoints
    };
  }

  return {
    amountAfterDiscount: amount,
    usedAffiliatePoints: 0
  };
}

export default getDiscountOffer;
