import React from "react";
import withUserData from "./withUserData";
import UniversalTableCore from "../lib/UniversalTable/UniversalTableCore";
import UniversalTableContext from "../lib/UniversalTable/UniversalTableContext";

export default function withUniversalTable(WrappedComponent) {
  @withUserData
  class WithUniversalTable extends UniversalTableCore {
    render() {
      const { resourceId, translate } = this.props;

      if (typeof resourceId === "undefined" || resourceId === null) {
        return translate("No resource for table provided");
      }

      return (
        <UniversalTableContext.Provider value={this.state.UniversalTable}>
          <WrappedComponent
            {...this.props}
            getColumns={this.getColumns}
            getColumnPairs={this.getColumnPairs}
            fetchNextPage={this.fetchNextPage}
            setTab={this.setTab}
          />
        </UniversalTableContext.Provider>
      );
    }
  }

  return WithUniversalTable;
}
